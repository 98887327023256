import React, { Fragment, useState, useEffect } from "react";
import Img from "gatsby-image";
import CustomSlickSlider from "./components/custom-slick-slider";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import Axios from "axios";
import { Helmet } from 'react-helmet';
import _ from 'lodash';

const PropertyCare = ({ data }) => {
  const propertyCare = data;
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState('');

  function getWindowDimensions() {
    if (typeof window !== "undefined" || typeof window !== undefined) {
      const { innerWidth: width, innerHeight: height } = window;

      if (width <= 414) {
        return true;
      } else {
        return false
      }
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const header_descriptor = propertyCare?.allContentfulDescriptor?.nodes?.find(item => item.contentful_id === '2IAaqYoYs80DuqXEHtCAqP');
  const descriptor1 = propertyCare?.allContentfulDescriptor?.nodes?.find(item => item.contentful_id === 'NU5ll7pSd9x7cPxzbxArp');
  const descriptor2 = propertyCare?.allContentfulDescriptor?.nodes?.find(item => item.contentful_id === '6rTLiaduhUMtZ8kWzgZaTD');
  const service_descriptor = propertyCare?.allContentfulDescriptor?.nodes?.find(item => item.contentful_id === '5xfgqBjiIsvxb4nEUtXbPI');

  const blogs = propertyCare?.allContentfulBlog?.nodes;
  const oderedBlogData = _.orderBy(blogs, [blogs => blogs.title], ['desc'])

  const connectNowHandler = (event) => {
    event.preventDefault();
    if (email) {
      const body = {
        "email": email
      };

      Axios.post("https://api.lqam.com/users/send-subscribe-user-request", body)
        .then(response => {
          if (response?.data?.statusCode === 200) {
            setEmailResponse('Your request has been sent successfully');
          }
        }).catch(err => {
        })
    }
  };


  return propertyCare ? (
    <Fragment>
      <Helmet>
        <title>Luxury Property Care</title>
      </Helmet>
      <BackgroundImage
        className="banner luxury-care-banner"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={propertyCare?.contentfulHeroHeader?.headerImage?.fluid}
      >
        <div className="container">
          <div className="banner-wrapper">
            <h1 className="heading font-white br-none property-care-hero-title">
              {propertyCare?.contentfulHeroHeader?.headerTitle}
            </h1>
          </div>
        </div>
      </BackgroundImage>

      <section className="come-home bg-gray">
        <div className="container">
          <div className="come-home-wrapper">
            <div className="title-wrapper">
              <h2 className="theme-title text-center br-none">
                {
                  header_descriptor.title
                }
              </h2>
              <p className="theme-desp text-center">
                {
                  header_descriptor.description
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style={{ margin: '0 12px' }}>
        <div className="container">
          <CustomSlickSlider sliderData={propertyCare?.allContentfulPropertyCareCarousel?.nodes} />
        </div>
      </section>

      <BackgroundImage
        className="luxury-house-banner-property"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={isMobile ? service_descriptor?.mobileImage?.fluid : service_descriptor?.image?.fluid}
      >
        <div className="container text-center" style={{ maxWidth: "700px" }}>
          <h2 className="theme-title">
            {
              service_descriptor?.title
            }
          </h2>
          <p className="text-blue br-none">
            {
              service_descriptor?.description
            }
          </p>
        </div>
      </BackgroundImage>
      <section className="connect-with luxury-care-connect">
        <div className="container">
          <div className="connect-with-wrapper">
            <div className="title-wrapper">
              <h2 className="theme-title text-center br-none">
                {propertyCare?.contentfulConnectHeaders?.title}
              </h2>
            </div>
            <form className="connect-from" onSubmit={(e) => connectNowHandler(e)}>
              <div className="form-field">
                <input
                  type="text"
                  placeholder="Contact LQAM to manage your home. "
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {
                  emailResponse ? <p style={{ marginTop: 4 }}>{emailResponse}</p> : null
                }
              </div>
              <div className="submit-btn">
                <input
                  type="submit"
                  className="theme-button"
                  value="CONNECT NOW"
                />
              </div>
            </form>
          </div>
        </div>
      </section>

      <section
        className="luxury-problem-listing listing-reverse"
        style={{
          background: "url('/img/service-bg.png')",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 left">
              <div className="title-wrapper">
                <h2 className="theme-title br-none">
                  {
                    descriptor1?.title
                  }
                </h2>
                <p className="theme-desp">
                  {
                    descriptor1?.description
                  }
                </p>
              </div>
            </div>
            <div className="col-md-8 banner-block blur-bg-none">
              <Img
                fluid={
                  descriptor1?.image?.fluid
                }
                alt="mobile mockup"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="luxury-problem-listing bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-4 left">
              <div className="title-wrapper">
                <h2 className="theme-title br-none">
                  {
                    descriptor2?.title
                  }
                </h2>
                <p className="theme-desp">
                  {
                    descriptor2?.description
                  }
                </p>
              </div>
            </div>
            <div className="col-md-8 banner-block blur-bg-none">
              <Img
                fluid={
                  descriptor2?.image?.fluid
                }
                alt="mobile mockup"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="about blog-posts"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundColor: '#fff'
        }}
      >
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              {oderedBlogData &&
                oderedBlogData?.length > 0 &&
                oderedBlogData?.map(
                  (item, index) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="col-md-6 about-col" id="property">
                          <Link to={`/${item?.redirectLink}/`}>
                            <span className="img">
                              <Img fluid={item?.image?.fluid} stye alt="Property Care" />
                            </span>
                            <div className="title-wrapper">
                              <h2 className="theme-title">{item?.title}</h2>
                              <p className="theme-desp">{item?.description}</p>
                            </div>
                            <Link to={`/${item?.redirectLink}/`} className="more-link">
                              Learn More
                            </Link>
                          </Link>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default PropertyCare;
