import React, { createRef, useState } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";

const CustomSlickSlider = ({ sliderData }) => {
  const [selectedImage, setSelectedImage] = useState(
    sliderData[0].sliderImage.fluid
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = createRef();

  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => slider.current.slickPrev()}
        >
        </button>
        <button
          className="arrow-btn next"
          onClick={() => slider.current.slickNext()}
        >
        </button>
      </div>
    );
  };

  const renderSlickDots = () => {
    return (
      <ul class="custom-slick-dots">{
        sliderData?.map((item, index) => {
          return (
            <li class={index === currentSlide ? 'slick-active-class' : ''}>
              <button>{index}</button>
            </li>
          )
        })
      }
      </ul>
    )
  }


  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    focusOnSelect: true,
    slidesToScroll: 5,
    slidesToShow: 4,
    focusOnSelect: true,
    dots: true,
    cssEase: "linear",
    speed: 500,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "20px",
          infinite: true,
          dots: false,
          arrow: false,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "20px",
          infinite: true,
          dots: false,
          arrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
      const currentSlide = sliderData.find((item, index) => index === current);
      setSelectedImage(currentSlide.sliderImage.fluid);
    },
  };


  const renderImage = () => {
    return (
      <Img
        fluid={selectedImage}
        fadeIn={true}
        loading="eager"
        durationFadeIn={1}
        className="property-slider-img"
        alt=""
        style={{
          width: "100%",
          maxHeight: "450px",
          objectFit: "cover",
          borderRadius: "7px",
        }}
      />
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="slick-slider-img">
        {renderImage()}
        {renderArrows()}
      </div>

      <Slider arrows={false}  {...settings} ref={slider} className="custom-slick-slider">
        {sliderData.map((item) => {
          return (
            <div key={item.id} className="slick-content">
              <div className="slick-content-wrapper">
                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                  {item.title}
                </div>
                <p style={{ fontSize: "16px" }}>{item.subTitle}</p>
              </div>
            </div>
          );
        })}
      </Slider>
      {renderSlickDots()}
    </div>
  );
};

export default CustomSlickSlider;
