import React from "react";
import { graphql } from "gatsby";
import PropertyCare from "../components/property-care";

const PropertyCarePage = ({ data }) => {
  return <PropertyCare data={data} />
};

export default PropertyCarePage;

export const query = graphql`
  {
     allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }
   contentfulConnectHeaders(contentful_id: {eq: "1LPznVwPSc3DyQvH2pi2ls"}) {
    title
  }
    allContentfulDescriptor(
      filter: {contentful_id: {in: ["2IAaqYoYs80DuqXEHtCAqP", "NU5ll7pSd9x7cPxzbxArp","6rTLiaduhUMtZ8kWzgZaTD","5xfgqBjiIsvxb4nEUtXbPI"]}}
        ) {
      nodes {
        description
        title
        contentful_id
        image {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
        mobileImage {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
        }
      }
    }
    
   allContentfulBlog(
        filter: {contentful_id: {in: ["2qsCxN7dCdusZDJ5ae1yBa", "3xkX7hB8J1XIyX1DowdTrs"]}}
      ) {
        nodes {
          title
          redirectLink
          description
          contentful_id
          image {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
  }
   contentfulHeroHeader(contentful_id: {eq: "19hNMr5Jl7T73M8Av1NSze"}) {
    headerTitle
    headerImage {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }

    allContentfulPropertyCareCarousel {
      nodes {
        title
        id
        subTitle
        sliderImage {
          fluid(maxHeight: 600, maxWidth: 1500, cornerRadius: 7, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
  }
`;
